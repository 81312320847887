.Table {
  // position: relative;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  tr {
    td {
      color: $colorGrayLighter;
      font-weight: 300;
      font-size: 14px;
      padding: 8px 0;
      border-bottom: 1px solid $colorBorder;
      vertical-align: middle;
      &:first-child {
        padding-left: 8px;
      }
      &:last-child {
        padding-right: 8px;
      }
      @include phone {
        font-size: 10px;
      }

      .storyInfo {
        line-height: 18px;
        max-width: 250px;
        span {
          font-weight: 500;
          color: $colorPrimary;
        }
      }
    }
  }

  thead {
    tr {
      th {
        color: $colorGray;
        font-weight: 500;
        border-bottom: 2px solid $colorPrimaryLighter;
        text-align: left;
        padding-bottom: 8px;
        font-size: 15px;

        &.sortable {
          text-decoration: underline;
          cursor: pointer;

          &.sorting {
            color: $colorPrimary;
            text-decoration: none;

            &__up {
              &::after {
                content: " ▲";
              }
            }

            &__down {
              &::after {
                content: " ▼";
              }
            }
          }

          &:hover {
            color: $colorPrimaryLighter;
          }
        }

        @include phone {
          font-size: 10px;
        }
      }
    }
  }

  tbody {
    tr {
      transition: background-color 0.3s;

      &:hover {
        background-color: $colorBackground;
      }
    }
  }

  .table-actions {
    position: absolute;
    top: 12px;
    right: 12px;

    li {
      display: inline-block;
      margin: 0px 5px;
      span {
        font-size: 11px;
      }
      a {
        font-size: 11px;
        color: $colorGray;
        font-weight: bold;

        &:hover {
          color: $colorPrimary;
        }
      }
    }
  }
}
