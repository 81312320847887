nav.Nav {
  position: fixed;
  width: $navHeaderWidthHeight;
  // height: calc(100vh - 64px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $colorWhite;
  border-right: 1px solid $colorBorder;

  a {
    display: flex;
    position: relative;
    width: $navHeaderWidthHeight;
    height: $navHeaderWidthHeight;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $colorBorder;
    cursor: pointer;

    &.text {
      font-size: 11px;
      text-align: center;
      font-weight: bold;
      line-height: 13px;
      transition: color 0.3s;

      &:hover,
      &.active {
        color: $colorPrimary;
      }
    }

    &.flipIconHorizontally {
      svg {
        transform: rotateY(180deg);
      }
    }

    &:first-child {
      border-top: 1px solid $colorBorder;
    }

    svg path {
      fill: $colorGray;
      transition: fill 0.3s;
    }

    &.active,
    &:hover {
      svg path {
        fill: $colorPrimary;
      }
    }

    &.active {
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 24px;
        background-color: $colorPrimaryLighter;
        right: 0;
      }
    }
  }
}
