@import "./variables.scss";
@import "./mixins.scss";
@import "./typography.scss";

@import "./reset.scss";
@import "./components/App.scss";

@import "./components/partials/Header.scss";
@import "./components/partials/Nav.scss";
@import "./components/partials/BreadCrumbs.scss";

@import "./components/common/WidgetContainer.scss";
@import "./components/common/Table.scss";
@import "./components/common/Button.scss";
@import "./components/common/Forms.scss";
@import "./components/common/Tray.scss";

@import "./components/screens/LoginScreen.scss";

* {
  box-sizing: border-box;
}

body {
  background-color: $colorBackground;
  font-family: $fontSans;
  color: $colorGray;
  font-size: 16px;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

hr {
  display: block;
  background-color: $colorBorder;
  width: 100%;
  height: 1px;
  border: none;
  margin: 16px 0;
}

.content {
  position: absolute;
  width: calc(100vw - #{$navHeaderWidthHeight});
  height: calc(100vh - #{$navHeaderWidthHeight});
  top: $navHeaderWidthHeight;
  left: $navHeaderWidthHeight;
  padding: $contentPadding;
}

.label {
  display: inline-block;
  font-size: 13px;
  padding-bottom: 8px;
  color: $colorGrayLightest;
}

.listItem {
  position: relative;
  font-size: 13px;
  padding: 8px 0;
  transition: background-color 0.3s;

  &:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    right: 5px;
    transition: all 0.3s;
  }

  &:hover {
    background-color: $colorBackground;

    &:after {
      background-color: $colorPrimary;
      right: 0px;
    }
  }

  .listItemName {
    color: $colorGrayLightest;
  }
  .listItemValue {
    color: $colorGray;
  }
}
