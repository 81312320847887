.LoginScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(
    100vh - #{$contentPadding} - #{$contentPadding} - #{$navHeaderWidthHeight}
  );
  .WidgetContainer {
    width: 300px;
    text-align: center;
  }
}
