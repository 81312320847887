.Button {
  display: inline-block;
  padding: 8px 16px;
  background-color: $colorPrimary;
  border: 1px solid $colorPrimaryDarker;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
  text-align: center;
  color: $colorWhite;
  text-decoration: none;
  font-weight: 500;

  transition: background-color 0.3s;

  cursor: pointer;

  &:hover {
    background-color: $colorPrimaryDarker;
    color: $colorWhite;
  }
}
