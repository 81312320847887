//colors
$colorWhite: #fff;
$colorBackground: #f5f6fa;
$colorBorder: #e7e7ef;
$colorGray: #545454;
$colorGrayLighter: #6f7072;
$colorGrayLightest: lighten($colorGrayLighter, 25%);
$colorPrimary: #7b3cbf;
$colorPrimaryLighter: lighten($colorPrimary, 25%);
$colorPrimaryDarker: darken($colorPrimary, 7%);

//sizing
$navHeaderWidthHeight: 64px;
$contentPadding: 32px;

//fonts
$fontSans: "Poppins", sans-serif;
$fontSerif: "Spectral", serif;
