.BreadCrumbs {
  position: relative;
  display: flex;
  width: 100%;

  font-size: 14px;
  color: $colorGrayLighter;
  padding-bottom: 32px;
  
  .BreadCrumbs-left {
    align-self: flex-start;
    width: 50%;
    span.BreadCrumbs-title {
    }
  
    span.BreadCrumbs-campaign-name {
      padding-left: 8px;
      font-weight: 600;
    }

  }

  .BreadCrumbs-right {
    align-self: flex-end;
    text-align: right;
    width: 50%;

  }


}
