.Tray {
  position: fixed;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  padding: 64px 12px 12px 36px;
  background-color: $colorWhite;
  border-left: 1px solid $colorBorder;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  transform: translateX(400px);
  transition: transform 0.3s ease;
  &.visible {
    transform: translateX(0);
  }

  .Tray__close {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    cursor: pointer;
    height: 100vh;
    width: 24px;
    font-size: 18px;
    color: $colorGrayLighter;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;
    line-height: 100vh;
    border-right: 1px solid lighten($colorBorder, 5%);

    transition: all 0.3s;
    &:hover {
      color: $colorGray;
      background-color: rgba(0, 0, 0, 0.05);
      border-right: 1px solid lighten($colorBorder, 0%);
    }
  }

  &.Tray_left {
    right: auto;
    left: 0;
    transform: translateX(-400px);
    padding: 12px 36px 12px 12px;

    &.visible {
      transform: translateX(0);
    }
    .Tray__close {
      left: auto;
      right: 0;
      border-right: none;
      border-left: 1px solid lighten($colorBorder, 5%);

      &:hover {
        border-left: 1px solid lighten($colorBorder, 0%);
      }
    }
  }
}
