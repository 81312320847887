input,
textarea {
  &::-webkit-input-placeholder {
    color: $colorGrayLightest;
  }
  &::-moz-placeholder {
    color: $colorGrayLightest;
  }
  &:-ms-input-placeholder {
    color: $colorGrayLightest;
  }
  &:-moz-placeholder {
    color: $colorGrayLightest;
  }
}

.TextInput {
  width: 100%;

  input {
    width: 100%;
    height: 32px;
    margin: 4px 0;
    font-size: 14px;
    color: $colorGray;
    border-radius: 4px;
    border: 1px solid $colorBorder;
    padding: 8px;
    outline: none;
  }
}
