.WidgetContainer {
  position: relative;
  display: block;

  background-color: $colorWhite;
  min-height: 16px;

  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;
  box-shadow: 0 0 10px darken($colorBackground, 7%);

  &.fill-height {
    height: calc(100% - 32px);
  }

  .WidgetContainer-title {
    font-size: 17px;
    font-weight: 500;
  }

  .WidgetContainer-subtitle {
    font-size: 14px;
    margin-top: 8px;
    color: $colorPrimary;
  }

  .WidgetContainer-content {
    margin-top: 16px;
  }

  .WidgetContainer-footer {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
    color: $colorGrayLightest;
  }
}
