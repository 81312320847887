@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600);
@import url(https://fonts.googleapis.com/css?family=Spectral:400,500,700);
@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

header.Header {
  position: fixed;
  width: 100%;
  height: 64px;
  background-color: #fff;
  border-bottom: 1px solid #e7e7ef;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10; }
  header.Header a.logo {
    align-self: flex-start;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px; }
  header.Header .brand {
    height: 32px;
    max-width: 300px;
    align-self: flex-end; }

nav.Nav {
  position: fixed;
  width: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-right: 1px solid #e7e7ef; }
  nav.Nav a {
    display: flex;
    position: relative;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e7e7ef;
    cursor: pointer; }
    nav.Nav a.text {
      font-size: 11px;
      text-align: center;
      font-weight: bold;
      line-height: 13px;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
      nav.Nav a.text:hover, nav.Nav a.text.active {
        color: #7b3cbf; }
    nav.Nav a.flipIconHorizontally svg {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg); }
    nav.Nav a:first-child {
      border-top: 1px solid #e7e7ef; }
    nav.Nav a svg path {
      fill: #545454;
      -webkit-transition: fill 0.3s;
      transition: fill 0.3s; }
    nav.Nav a.active svg path, nav.Nav a:hover svg path {
      fill: #7b3cbf; }
    nav.Nav a.active:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 24px;
      background-color: #bc9be0;
      right: 0; }

.BreadCrumbs {
  position: relative;
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #6f7072;
  padding-bottom: 32px; }
  .BreadCrumbs .BreadCrumbs-left {
    align-self: flex-start;
    width: 50%; }
    .BreadCrumbs .BreadCrumbs-left span.BreadCrumbs-campaign-name {
      padding-left: 8px;
      font-weight: 600; }
  .BreadCrumbs .BreadCrumbs-right {
    align-self: flex-end;
    text-align: right;
    width: 50%; }

.WidgetContainer {
  position: relative;
  display: block;
  background-color: #fff;
  min-height: 16px;
  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;
  box-shadow: 0 0 10px #dde1ee; }
  .WidgetContainer.fill-height {
    height: calc(100% - 32px); }
  .WidgetContainer .WidgetContainer-title {
    font-size: 17px;
    font-weight: 500; }
  .WidgetContainer .WidgetContainer-subtitle {
    font-size: 14px;
    margin-top: 8px;
    color: #7b3cbf; }
  .WidgetContainer .WidgetContainer-content {
    margin-top: 16px; }
  .WidgetContainer .WidgetContainer-footer {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
    color: #afb0b1; }

.Table {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px; }
  .Table tr td {
    color: #6f7072;
    font-weight: 300;
    font-size: 14px;
    padding: 8px 0;
    border-bottom: 1px solid #e7e7ef;
    vertical-align: middle; }
    .Table tr td:first-child {
      padding-left: 8px; }
    .Table tr td:last-child {
      padding-right: 8px; }
    @media (min-width: 320px) and (max-width: 767px) {
      .Table tr td {
        font-size: 10px; } }
    .Table tr td .storyInfo {
      line-height: 18px;
      max-width: 250px; }
      .Table tr td .storyInfo span {
        font-weight: 500;
        color: #7b3cbf; }
  .Table thead tr th {
    color: #545454;
    font-weight: 500;
    border-bottom: 2px solid #bc9be0;
    text-align: left;
    padding-bottom: 8px;
    font-size: 15px; }
    .Table thead tr th.sortable {
      text-decoration: underline;
      cursor: pointer; }
      .Table thead tr th.sortable.sorting {
        color: #7b3cbf;
        text-decoration: none; }
        .Table thead tr th.sortable.sorting__up::after {
          content: " ▲"; }
        .Table thead tr th.sortable.sorting__down::after {
          content: " ▼"; }
      .Table thead tr th.sortable:hover {
        color: #bc9be0; }
    @media (min-width: 320px) and (max-width: 767px) {
      .Table thead tr th {
        font-size: 10px; } }
  .Table tbody tr {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .Table tbody tr:hover {
      background-color: #f5f6fa; }
  .Table .table-actions {
    position: absolute;
    top: 12px;
    right: 12px; }
    .Table .table-actions li {
      display: inline-block;
      margin: 0px 5px; }
      .Table .table-actions li span {
        font-size: 11px; }
      .Table .table-actions li a {
        font-size: 11px;
        color: #545454;
        font-weight: bold; }
        .Table .table-actions li a:hover {
          color: #7b3cbf; }

.Button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #7b3cbf;
  border: 1px solid #6a33a4;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer; }
  .Button:hover {
    background-color: #6a33a4;
    color: #fff; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #afb0b1; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #afb0b1; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #afb0b1; }

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #afb0b1; }

.TextInput {
  width: 100%; }
  .TextInput input {
    width: 100%;
    height: 32px;
    margin: 4px 0;
    font-size: 14px;
    color: #545454;
    border-radius: 4px;
    border: 1px solid #e7e7ef;
    padding: 8px;
    outline: none; }

.Tray {
  position: fixed;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  padding: 64px 12px 12px 36px;
  background-color: #fff;
  border-left: 1px solid #e7e7ef;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-transform: translateX(400px);
          transform: translateX(400px);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .Tray.visible {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .Tray .Tray__close {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    cursor: pointer;
    height: 100vh;
    width: 24px;
    font-size: 18px;
    color: #6f7072;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;
    line-height: 100vh;
    border-right: 1px solid #f6f6f9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
    .Tray .Tray__close:hover {
      color: #545454;
      background-color: rgba(0, 0, 0, 0.05);
      border-right: 1px solid #e7e7ef; }
  .Tray.Tray_left {
    right: auto;
    left: 0;
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
    padding: 12px 36px 12px 12px; }
    .Tray.Tray_left.visible {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .Tray.Tray_left .Tray__close {
      left: auto;
      right: 0;
      border-right: none;
      border-left: 1px solid #f6f6f9; }
      .Tray.Tray_left .Tray__close:hover {
        border-left: 1px solid #e7e7ef; }

.LoginScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc( 100vh - 32px - 32px - 64px); }
  .LoginScreen .WidgetContainer {
    width: 300px;
    text-align: center; }

* {
  box-sizing: border-box; }

body {
  background-color: #f5f6fa;
  font-family: "Poppins", sans-serif;
  color: #545454;
  font-size: 16px; }

h2 {
  font-size: 22px;
  font-weight: 600; }

h3 {
  font-size: 18px;
  font-weight: 600; }

h4 {
  font-size: 16px;
  font-weight: 500; }

hr {
  display: block;
  background-color: #e7e7ef;
  width: 100%;
  height: 1px;
  border: none;
  margin: 16px 0; }

.content {
  position: absolute;
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
  top: 64px;
  left: 64px;
  padding: 32px; }

.label {
  display: inline-block;
  font-size: 13px;
  padding-bottom: 8px;
  color: #afb0b1; }

.listItem {
  position: relative;
  font-size: 13px;
  padding: 8px 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }
  .listItem:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    right: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  .listItem:hover {
    background-color: #f5f6fa; }
    .listItem:hover:after {
      background-color: #7b3cbf;
      right: 0px; }
  .listItem .listItemName {
    color: #afb0b1; }
  .listItem .listItemValue {
    color: #545454; }

