header.Header {
  position: fixed;
  width: 100%;
  height: $navHeaderWidthHeight;

  background-color: $colorWhite;
  border-bottom: 1px solid $colorBorder;

  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10;
  a.logo {
    align-self: flex-start;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
  }

  .brand {
    height: 32px;
    max-width: 300px;

    align-self: flex-end;
  }
}
